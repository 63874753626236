import React from 'react';
import ReactDropZone from 'react-dropzone';
import {useField} from 'formik';
import './styles.scss';
import {formatBytes} from '@utils/files';

interface IDropZone {
  name: string;
  label: string;
  placeholder?: string;
  icon?: string;
  requiredMark?: boolean;
}

const DropZone: React.FC<IDropZone> = ({label, requiredMark = false, icon, ...props}) => {
  const [field, meta, helper] = useField(props.name);

  const inputId = `input-${props.name}`;

  const error = meta.error ? meta.error : '';

  const onDrag = (acceptedFiles: File[]) => {
    helper.setTouched(true);
    helper.setValue(acceptedFiles[0]);
  };

  const value = field.value;

  return (
    <>
      <div className="containerFormsCarreInput">
        <ReactDropZone onDrop={onDrag} multiple={false}>
          {({getRootProps, getInputProps, isDragActive}) => (
            <div {...getRootProps()} className="w-100">
              <label htmlFor="dragndrop" className="dragNdropInput">
                <input {...getInputProps()} id={inputId} />
                {isDragActive ? (
                  <>
                    <p className="dragActiveText size-large size-veryLarge pt-20">
                      Déposer votre fichier ici 👇
                    </p>
                  </>
                ) : (
                  <>
                    <div className="dragNdropInput-content">
                      <p className="size-large w-100 text-center">
                        {label}
                        {requiredMark && <i className="fc-red-STATUS-primary"> *</i>}
                      </p>
                      {!value && (
                        <>
                          <p className="weight-light size-tiny mt-5">
                            Fichiers supportés : xml. Taille maximum : 2Mo
                          </p>
                        </>
                      )}
                      {value && (
                        <div className="selectedFileContainer">
                            <span className="selectedFileName flex flex-alignCenter">
                              <i className="fc-green-STATUS-primary icon-short-check mr-5"></i>
                              {value.name}
                            </span>
                          <span className="weight-light flex flex-alignCenter">
                                  {formatBytes(value.size)}
                          </span>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </label>
            </div>
          )}
        </ReactDropZone>
        <div
          className={`size-small mt-2 mb-2 ${
            value ? '' : 'fc-red-STATUS-primary'
          }`}
        >
          {error}
        </div>
      </div>
    </>
  );
};

export default DropZone;
