import React, {Children} from 'react';
import {Field, useField} from 'formik';
import {isUndefined} from '@lepicard/utils';
import {cn} from '@utils/class-names';
import './styles.scss';

interface IRadioGroup {
  name: string;
  children: (itemProps: IRadioGroupChildrenProps) => React.ReactChild;
}

export interface IRadioGroupChildrenProps {
  name: string;
  fieldValue: any;
  setValue: (newValue: any) => void;
}

const RadioGroup: React.FC<IRadioGroup> = ({name, children, ...props}) => {
  const [field, meta, helper] = useField(name);

  const showError = meta.touched && !isUndefined(meta.error);

  console.log('radioGroup', name, field, meta);

  const setValue = (newValue: any) => helper.setValue(newValue);

  return <div className={cn('lpa-radio-group', {isError: showError})}>
    <Field name={name}>
      {() => children({...props, name, fieldValue: field.value, setValue})}
    </Field>
  </div>;
};

export default RadioGroup;
