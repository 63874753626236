import React, {useEffect, useMemo, useState} from 'react';
import {ErrorMessage, Form, FormikProps} from 'formik';
import Select from '@components/NewFields/Select';
import {format, sub, add} from 'date-fns';
import Input from '@components/NewFields/Input';
import RadioGroup from '@components/NewFields/RadioGroup';
import RadioItem from '@components/NewFields/RadioItem';
import Button from '@components/Buttons/Button';
import Alert from '@components/Alert';
import {observer} from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import routes from '@routes';
import './styles.scss';
import {frenchStringToNumber} from '@utils/string';
import useHandleSubmissionError from '@hooks/useHandleSubmissionError';
import {defaultErrorToast} from '@utils/toast';
import {toaster} from '@utils/dictionnary';
import QuickHelp from '@components/UI/QuickHelp/QuickHelp';
import {useStore} from '@stores/index';
import DropZone from '@components/NewFields/DropZone';
import EnvironmentService from '@utils/environmentService';
import CheckBox from '@components/NewFields/CheckBox';

export interface IDurabilityForm {
  year: string;
  pacageNumber: string | null;
  managerName: string;
  area: string;
  newAreas?: boolean;
  statementType: boolean;
  document?: File;
  bleArea: string;
  colzaArea: string;
  bleDurability: string;
  colzaDurability: string;
  cultureLength: string;
  authorPhone: string;
  authorEmail: string;
}

interface IDurabilityFormik extends FormikProps<IDurabilityForm> {
  error: string | null;
  isPending: boolean | undefined;
}

const FormContent: React.FC<IDurabilityFormik> = observer(
  ({values, errors, isPending, submitCount, isValid, setFieldValue, touched}) => {
    const {sso: SSOStore} = useStore();
    const {selectedExtranetUser: currentAccount} = SSOStore;

    const currentYear = new Date();
    const peviousYear = sub(new Date(), {years: 1});
    const nextYear = add(new Date(), {years: 1});

    const formatDateToYear = (date: Date) => format(date, 'yyyy');

    const yearOptions = [
      {
        value: formatDateToYear(peviousYear),
      },
      {
        value: formatDateToYear(currentYear),
      },
      {
        value: formatDateToYear(nextYear),
      },
    ];

    const totalArea = useMemo(() => {
      let total = 0;

      if (values.bleArea) {
        total = total + frenchStringToNumber(values.bleArea);
      }

      if (values.colzaArea) {
        total = total + frenchStringToNumber(values.colzaArea);
      }

      return total.toFixed(2).replace('.', ',');
    }, [values.bleArea, values.colzaArea]);

    const durablePercent = useMemo(() => {
      let total = 0;

      if (values.bleArea) {
        total = total + frenchStringToNumber(values.bleArea);
      }

      if (values.colzaArea) {
        total = total + frenchStringToNumber(values.colzaArea);
      }

      let totalDurable = 0;

      if (values.bleDurability) {
        totalDurable = totalDurable + frenchStringToNumber(values.bleDurability);
      }

      if (values.colzaDurability) {
        totalDurable = totalDurable + frenchStringToNumber(values.colzaDurability);
      }

      return total > 0 ? ((totalDurable / total) * 100).toFixed(0) : 0;
    }, [values]);

    useEffect(() => {
      const regexRule = /\./g;
      ['bleArea', 'cultureLength', 'bleDurability', 'colzaArea', 'colzaDurability'].forEach((key) => {
        // @ts-ignore
        const value = values[key] as string;
        if (regexRule.test(value)) {
          setFieldValue(key, value.replaceAll('.', ','));
        }
      });
    }, [values]);

    useHandleSubmissionError(submitCount, isValid, () => {
      defaultErrorToast(toaster.error.form);
    });

    return (
      <Form className="Card w-100 relative p-[14px] md:p-[30px]">
        <div>
          <p className="durabilityTitle">Déclaration de respect des critères de durabilité Blé/Colza</p>
          <p className="durabilitySubTitle">
            Cette auto déclaration ne vous engage en aucun cas à livrer votre récolte chez{' '}
            {EnvironmentService.instanceConfig.global.instanceFullName}.
          </p>
          <div className="mb-0 p-0 w-1/6">
            <div className="flex">
              <span className="flex items-center mr-2 durabilityCampaign text-green-100">Campagne</span>
              <div className="flex items-center mr-3 text-green-100">
                <QuickHelp>Année en cours, n-1, n+1</QuickHelp>
              </div>
              <Select name="year" options={yearOptions} className="campagnFilter" />
            </div>
          </div>
        </div>
        <div className="line"></div>

        {currentAccount && (
          <>
            <p className="durabilityTitle">Informations pour la récolte</p>
            <p className="durabilityMandatory">* Champs Obligatoires</p>
            <div className="harvestInformation">
              <div className="durability_threeItemsRow">
                <div className="durability_ValueName">Nom de l’exploitation</div>
                <div className="durability_Value">{currentAccount.contact?.name}</div>
              </div>
              <div className="durability_threeItemsRow">
                <span className="durability_ValueName">Numéro apporteur</span>
                <span className="durability_Value">{currentAccount.contact?.vatRegistrationNum}</span>
              </div>
              <div className="durability_threeItemsRow mb-0">
                <span className="durability_ValueName">Numéro SIRET</span>
                <span className="durability_Value">{currentAccount.contact?.registrationNum}</span>
              </div>
            </div>
            <p className="durabilityMention">
              Si le numéro de SIRET n’est pas correct, veuillez faire la demande de modification dans la section{' '}
              <Link to={routes.PROFILE} className="durability_myProfile">
                Mon profil
              </Link>
            </p>

            <div className="line md:line mb-4"></div>

            <div className="flex flex-col md:flex-row">
              <div className="durability_twoItemsRow md:mr-8">
                <Input
                  name="managerName"
                  className="labelPlaceholder"
                  label="Gérant (nom et prénom)"
                  required
                  type="text"
                />

                <Input name="authorEmail" className="labelPlaceholder" label="Email" required type="text" />
              </div>
              <div className="durability_twoItemsRow">
                <Input name="authorPhone" className="labelPlaceholder" label="Téléphone" required type="tel" />

                <Input name="pacageNumber" className="labelPlaceholder" label="Numéro PACAGE" required type="text" />
              </div>
            </div>
            <div className="line"></div>
            <div>
              <div className="">
                <p className="durabilityTitle">
                  L’exploitant déclare que <i className="fc-red-STATUS-primary"> *</i>:
                </p>
                <p className="durabilitySubTitle">
                  {`L'exploitation dispose d’une surface totale de ${totalArea}Ha pour la récolte ${values.year} (dont
                  ${durablePercent} % de durable)`}
                </p>
              </div>
              <div className="flex flex-col md:flex-row md:mb-5">
                <div className="durability_twoItemsRow md:mr-8">
                  <span>
                    <Input
                      type="text"
                      className="labelPlaceholder"
                      name="cultureLength"
                      label="Nombre de cultures dans la rotation"
                      required
                    />
                  </span>
                </div>
                <div className="durability_twoItemsRow hidden md:invisible md:block">
                  <Input type="text" className="labelPlaceholder" name="bleDurability" label="dont durable" required />
                </div>
              </div>
              <div className="flex flex-col md:flex-row ">
                <div className="durability_twoItemsRow md:mr-8">
                  <span className="md:mb-5">
                    <Input
                      type="text"
                      className="labelPlaceholder"
                      name="bleArea"
                      label="Surface totale blé"
                      required
                    />
                  </span>
                  <Input type="text" className="labelPlaceholder" name="bleDurability" label="dont durable" required />
                </div>
                <div className="durability_twoItemsRow">
                  <span className="md:mb-5">
                    <Input
                      type="text"
                      className="labelPlaceholder"
                      name="colzaArea"
                      label="Surface totale colza"
                      required
                    />
                  </span>
                  <Input
                    type="text"
                    className="labelPlaceholder"
                    name="colzaDurability"
                    label="dont durable colza"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="line"></div>

            <div>
              <p className="durabilitySubTitle mb-4">
                {`L’exploitation présente de nouvelle(s) parcelle(s) (parcelles achetées, louées, échangées)`}
                <i className="fc-red-STATUS-primary"> *</i>
                {' : '}{' '}
              </p>
            </div>
            <div className="col-2">
              <RadioGroup name="newAreas">
                {(fieldProps) => (
                  <>
                    <RadioItem value={true} label="Oui" className="mr-10 mb-0 text-green-100" {...fieldProps} />
                    <RadioItem value={false} label="Non" className="mb-0 text-green-100" {...fieldProps} />
                  </>
                )}
              </RadioGroup>
              {touched.newAreas && errors.newAreas && <Alert className="mb-2 errorAlert" text={errors.newAreas} />}
            </div>

            <div className="line"></div>

            <div className="">
              <p className="durabilityTitle mb-8">
                L’exploitant déclare <i className="fc-red-STATUS-primary"> *</i>:
              </p>
              <div className="durabilitySubTitle">
                <CheckBox
                  name="statementType"
                  className="mb-8"
                  label={
                    <ol className="ml-8">
                      <li className="mb-4">
                        1. Que les productions agricoles provenant de mon exploitation sont cultivées sur des terres
                        déjà classées comme « terres labourables » au 1er Janvier 2008 (c’est-à-dire terres n’étant pas
                        un ancien bois, une ancienne prairie, un ancien chemin, une ancienne tourbière, ...)
                      </li>
                      <li className="mb-4">
                        2. Que les parcelles cultivées de mon exploitation ne sont pas concernées par une zone de
                        protection de la nature (ex: Natura 2000) ou à la protection d’écosystèmes ou d’espèces rares.
                        Si une partie de mon exploitation est concernée par une telle zone, je respecte la
                        réglementation spécifique à cette zone permettant les cultures.{' '}
                      </li>
                      <li className="mb-4">
                        3. Avoir respecté la réglementation spécifique au domaine Environnement de la conditionnalité de
                        la PAC et aux BCAE
                      </li>
                      <li>
                        4. Avoir perçu pour mon exploitation agricole, les aides PAC ou avoir déposé un dossier de
                        demande au titre de la déclaration PAC
                      </li>
                    </ol>
                  }
                />
                <div className={'fc-red-STATUS-primary'}>
                  <ErrorMessage name="statementType"></ErrorMessage>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="card-row">
          <div className="card-rowHeader mb-15">
            <p className="durabilityParagraph weight-medium size-large flex flex-alignCenter">
              <i className="durabilitySubTitle"></i>Comment récupérer mon Registre Parcellaire Graphique ?{' '}
            </p>
          </div>

          <div className="card-rowBody">
            <div className="card-row bg-white">
              <div className="form-row grid grid-6 grid-1Mobile grid-gap-16 m-0 p-0 m-0Mobile p-0Mobile">
                <div className="col-3 col-1Mobile border-right border-paleGrey p-25 p-25Mobile">
                  <p className=" weight-medium size-mediumS fc-black">
                    Afin de répondre aux nouvelles exigences de durabilité, je communique la localisation de mes
                    parcelles consacrées à ces productions je joins le fichier XML télécharger sur télépac
                    (obligatoire).
                  </p>
                  <ul className="durability-list">
                    <li>
                      <span className=" weight-medium fc-primary-primary">1.</span> Se connecter sur{' '}
                      <span className="weight-medium fc-moss-green-primary ">
                        <a target="_blank" href="https://www.telepac.agriculture.gouv.fr" rel="noreferrer">
                          https://www.telepac.agriculture.gouv.fr
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className=" weight-medium fc-primary-primary">2.</span> Sélectionner le dossier PAC de la
                      campagne pour laquelle vous souhaitez récupérer votre Fichier XML
                    </li>
                    <li>
                      <span className=" weight-medium fc-primary-primary">3.</span> Cliquer sur IMPORT/EXPORT
                    </li>
                    <li>
                      <span className=" weight-medium fc-primary-primary">4.</span> Cliquer sur « Export dossier complet
                      (XML) »
                    </li>
                    <li>
                      <span className=" weight-medium fc-primary-primary">5.</span> Cliquer sur « Export dossier PAC
                      (XML) Déclaré pour la campagne »
                    </li>
                    <li>
                      <span className=" weight-medium fc-primary-primary">6.</span> Télécharger le Fichier XML pour la
                      campagne choisie
                    </li>
                    <li>
                      <span className=" weight-medium fc-primary-primary">7.</span> Importer le fichier avec le lien
                      ci-dessous
                    </li>
                  </ul>
                  <DropZone name="document" label="Importer votre fichier XML" requiredMark />
                </div>

                <div className=" col-3 col-1Mobile p-25">
                  <p className=" weight-medium size-mediumS fc-black mb-10">
                    Télécharger le mode opératoire de téléchargement du fichier XML{' '}
                  </p>

                  <a
                    href="https://storage.googleapis.com/lp_public/LEPICARD%202024%20-%20Comment%20exporter%20un%20dossier%20XML.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button buttonClass="outline">Télécharger le mode opératoire</Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-3">
          <div>
            <p className="durabilityMention mb-8">
              Je m’engage à conserver et à tenir à disposition, à des fins de contrôle éventuel, l’ensemble des éléments
              permettant de démontrer la véracité de cette déclaration et donc le caractère durable de ma production
              agricole selon la Directive Européenne 2009/28/CE et ses modifications. Je m’engage par ailleurs à
              informer l’organisme collecteur de toutes modifications ultérieures concernant ma situation vis-à-vis des
              différents critères de durabilité ci-dessus.
            </p>
          </div>
          <div className={`md:flex ${Object.keys(errors).length > 0 ? 'md:justify-between' : 'md:justify-end'}`}>
            {Object.keys(errors).length > 0 && (
              <Alert text="* Champ requis invalide" className="col-3 col-1Mobile errorAlert" />
            )}
            <Button type="submit" className="w-full md:w-auto justify-center !bg-green-100" isLoading={isPending}>
              Signer
            </Button>
          </div>
        </div>
      </Form>
    );
  },
);
export default FormContent;
