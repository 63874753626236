import React, {useState} from 'react';
import {Formik, FormikProps} from 'formik';
import {format} from 'date-fns';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores/index';
import {useHistory} from 'react-router-dom';
import './styles.scss';
import {defaultErrorToast, defaultSuccesToast} from '@utils/toast';
import {toaster} from '@utils/dictionnary';
import useOnActionSuccess from '@hooks/useOnActionSuccess';
import {IRemoteDurability} from '@services/durability';
import validateDurabilityForm from './validate';
import FormContent, {IDurabilityForm} from './formContent';

const DurabilityForm: React.FC = observer(() => {
  const {durability: durabilityStore} = useStore();

  const [loaded, setLoaded] = useState<boolean>(false);
  const {isDone: postSuccess, error: postError} = durabilityStore.fetchDurability;
  const history = useHistory();
  const currentYear = format(new Date(), 'yyyy');

  const {sso: SSOStore} = useStore();
  const {selectedExtranetUser: currentAccount, user: currentUser} = SSOStore;
  const currentContact = currentAccount?.contact;

  useOnActionSuccess(postSuccess, () => {
    defaultSuccesToast(toaster.success.sign);
    setLoaded(false);
    history.push('/durabilitySuccess');
  });

  const getNumberToSubmit = (fieldNumber: string | number) => {
    const field = parseFloat(fieldNumber.toString().replace(',', '.').replace(' ', '')).toFixed(2);

    return field;
  };

  const escapeNaN = (number: number) => {
    return number ? number : 0;
  }

  const durabilitySubmit = (values: IDurabilityForm) => {
    try {
      setLoaded(true);
      const totalArea = values.bleArea + values.colzaArea;

      const manager = values.managerName.split(' ');

      const newDurability: IRemoteDurability = {
        ...values,
        newAreas: values.newAreas as boolean,
        bleArea: values.bleArea as unknown as number,
        area: parseFloat(totalArea) as number,
        colzaArea: values.colzaArea as unknown as number,
        colzaDurability: values.colzaDurability as unknown as number,
        bleDurability: values.bleDurability as unknown as number,

        colzaDurabilityRate: escapeNaN(((parseFloat(values.colzaDurability) / parseFloat(values.colzaArea)) * 100)).toFixed(0),

        bleDurabilityRate: escapeNaN((parseFloat(values.bleDurability) / parseFloat(values.bleArea)) * 100).toFixed(0),

        statementType: values.statementType ? 1 : 0 as number,
        cultureLength: getNumberToSubmit(values.cultureLength) as unknown as number,
        pacageNumber: values.pacageNumber ? values.pacageNumber : currentContact?.registrationNum  as string,
        authorId: currentAccount?.id as string,
        authorPhone: values.authorPhone,
        contactId: currentContact?.id as string,
        contactName: currentContact?.name as string,
        authorEmail: values.authorEmail,
        authorFirstName: currentContact?.firstname ? currentContact?.firstname as string : manager[1],
        authorLastName: currentContact?.lastname ? currentContact?.lastname as string : manager[0],
        city: currentContact?.city as string,
        registrationNum: currentContact?.registrationNum as string,
        vatRegistrationNum: currentContact?.vatRegistrationNum as string,
        campaignId: values.year,
      };

      const document = values.document ? values.document : null;

      durabilityStore.postDurability(newDurability, document).then((e) => {
        setLoaded(false);
      });
    } catch (e) {
      defaultErrorToast(JSON.stringify(e));
    }
  };

  const initialValues: IDurabilityForm = {
    year: currentYear,
    pacageNumber: '',
    managerName: '',
    area: '0',
    colzaArea: '0',
    colzaDurability: '0',
    bleArea: '0',
    bleDurability: '0',
    cultureLength: '0',
    authorPhone: '',
    authorEmail: '',
    statementType: false,
    newAreas: undefined,
    document: undefined
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={durabilitySubmit}
      validationSchema={validateDurabilityForm}
    >
      {(formikProps: FormikProps<IDurabilityForm>) => <FormContent {...formikProps} error={null} isPending={loaded} />}
    </Formik>
  );
});
export default DurabilityForm;
